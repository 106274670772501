@import "./assets/styles/tailwind.css";
@import "./assets/styles/colors.css";

html {
  margin:0;
  height:100%;
  background-color: var(--background-color);
  color: var(--neatcar);
}

body {
  margin:0;
  height: 100%;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
}

.error {
  color: var(--error);
  @apply text-xs;
}
