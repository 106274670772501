:root {
  --primary: theme('colors.primary');
  --background-color: theme('colors.neatcar');
  --neatcar-background: theme('colors.neatcarBackground');
  --neatcar-gray: theme('colors.neatcarGray');
  --neatcar: theme('colors.neatcar');
  --error: theme('colors.error');
  --warning: theme('colors.warning');
  --success: theme('colors.success');
}
